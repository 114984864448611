<div
  class="w-full min-w-[100px] h-12 border border-solid border-[#C6CCC6] rounded-lg text-base text-midnight flex items-center justify-between py-[5px] px-6 cursor-pointer overflow-hidden bg-primary-content transition"
  [ngClass]="{
    'z-[100000] focus': panelOpen,
    'shadow-input-focus !border-midnight': focused
  }"
  (click)="open()"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  #trigger
>
  <ng-container [ngSwitch]="searchable && panelOpen">
    <input
      #searchableInput
      *ngSwitchCase="true"
      [formControl]="searchableCtrl"
      [value]="'Input'"
      class="w-full focus:outline-none"
      placeholder="Start typing..."
    />
    <div *ngSwitchCase="false" class="overflow-hidden font-medium truncate">
      {{ displayValue }}
    </div>
  </ng-container>
  <div
    *ngIf="!icon"
    class="select-arrow"
    [ngClass]="{ 'caret-down': !panelOpen, 'caret-up': panelOpen }"
  ></div>
  <ng-content select="[etAtomsSelectIcon]"></ng-content>
</div>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayWidth]="windowWidth"
  [cdkConnectedOverlayPositions]="positions"
  (backdropClick)="close()"
  (attach)="onAttach()"
>
  <div class="select-panel-wrap">
    <div
      class="flex flex-col py-[5px] px-0 overflow-auto max-h-64 bg-primary-content rounded-lg"
      tabindex="-1"
      role="listbox"
      #panel
    >
      <ng-container #notFoundMsgContainer></ng-container>
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>

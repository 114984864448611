import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from '@et/auth';
import { DirectivesModule } from '@et/directives';
import { I18nModule } from '@et/i18n';
import { HomeComponent } from './home/home.component';
import { ApiModule } from '@et/api';
import { environment } from '@et/environment';
import { NgxsModule } from '@ngxs/store';
import { ProfileState } from '@et/guards';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { MSALProviders } from './add-config';
import { NavbarComponent } from './navbar/navbar.component';
import { AppRoutingModule } from './app-routing.module';
import { AppApiModule } from './app-api.module';
import { NotificationsModule } from '@et/notifications';

@NgModule({
  declarations: [AppComponent, HomeComponent, NavbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    DirectivesModule,
    HttpClientModule,
    NotificationsModule,
    I18nModule,
    AppRoutingModule,
    ApiModule.forRoot({
      rootUrl: `${environment.apiServer.domain}`,
    }),
    AppApiModule,
    NgxsModule.forRoot([ProfileState]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  providers: [...MSALProviders],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

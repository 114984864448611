<div class="text-secondary text-base min-h-[20px] flex gap-2 items-center">
  @if(multiple && !hideCheckbox) {
  <input
    [checked]="selected"
    type="checkbox"
    class="pointer-events-none checkbox checkbox-xs"
  />
  }

  <ng-container *ngIf="!displayValue">
    {{ value }}
  </ng-container>

  <span #content>
    <ng-content></ng-content>
  </span>
</div>

<div class="px-4 py-2 h-[96px] shadow-header bg-forest-dusk">
  <!-- Wrapper for buttons -->
  <div class="container flex h-full max-w-full p-0 mx-auto navbar">
    <!-- Left buttons wrapper -->
    <div class="flex justify-start w-1/2 mb-2 navbar-start">
      <a class="flex mr-1 cursor-pointer text-midnight group" routerLink="/">
        <img class="h-[48px] w-auto" src="assets/icons/evault-icon.svg" />
        <div class="flex mt-1 ml-6">
          <p class="text-4xl font-semibold align-text-bottom text-brand-green">
            et
          </p>
          <p class="ml-0 text-4xl font-semibold text-white align-text-bottom">
            vault
          </p>
        </div>
      </a>

      <div class="flex justify-center gap-4 mt-2 ml-5 text-center text-white">
        <span class="nav-divider"></span>
        <div
          routerLink="/app/enote-tools"
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:hover:text-brand-green green-hover"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_493_973)">
              <path
                d="M25.66 7.88721e-06H6.35004C4.6703 0.00265732 3.06023 0.671788 1.87341 1.86048C0.686594 3.04918 0.0200174 4.66027 0.0200195 6.34001V25.67C0.0226642 27.348 0.690421 28.9565 1.87695 30.1431C3.06349 31.3296 4.67203 31.9974 6.35004 32H25.6801C27.359 31.9974 28.9686 31.3299 30.1567 30.1437C31.3448 28.9574 32.0147 27.3489 32.02 25.67V6.34001C32.0187 5.50611 31.8532 4.68064 31.5328 3.91072C31.2125 3.14081 30.7437 2.44153 30.1531 1.85281C29.5625 1.26408 28.8617 0.797446 28.0908 0.479542C27.3199 0.161638 26.4939 -0.00130637 25.66 7.88721e-06ZM29.9901 25.67C29.9901 26.8184 29.5338 27.9197 28.7218 28.7318C27.9098 29.5438 26.8084 30 25.66 30H6.35004C5.20165 30 4.1003 29.5438 3.28827 28.7318C2.47624 27.9197 2.02002 26.8184 2.02002 25.67V6.34001C2.02002 5.1907 2.47591 4.08831 3.28766 3.27469C4.09941 2.46107 5.20073 2.00266 6.35004 2.00001H25.6801C26.8294 2.00266 27.9307 2.46107 28.7424 3.27469C29.5542 4.08831 30.01 5.1907 30.01 6.34001L29.9901 25.67Z"
                fill="white"
              />
              <path
                d="M16 5C13.8244 5 11.6977 5.64514 9.88875 6.85383C8.07981 8.06253 6.66986 9.78049 5.83729 11.7905C5.00473 13.8005 4.78694 16.0122 5.21138 18.146C5.63582 20.2798 6.68344 22.2398 8.22182 23.7782C9.76019 25.3165 11.7202 26.3642 13.854 26.7886C15.9878 27.2131 18.1995 26.9952 20.2095 26.1627C22.2195 25.3301 23.9374 23.9202 25.1461 22.1113C26.3548 20.3023 27 18.1756 27 16C27 13.0826 25.8411 10.2847 23.7782 8.22182C21.7153 6.15892 18.9174 5 16 5ZM16 25C14.22 25 12.4799 24.4722 10.9998 23.4832C9.51979 22.4943 8.36626 21.0887 7.68507 19.4441C7.00388 17.7996 6.82566 15.99 7.17293 14.2442C7.52019 12.4984 8.37733 10.8947 9.636 9.63604C10.8947 8.37736 12.4983 7.5202 14.2442 7.17293C15.99 6.82566 17.7996 7.00389 19.4442 7.68508C21.0887 8.36627 22.4943 9.51982 23.4832 10.9999C24.4722 12.4799 25 14.22 25 16C25.0013 17.1827 24.7695 18.3541 24.3178 19.4472C23.8661 20.5403 23.2034 21.5336 22.3675 22.3704C21.5317 23.2072 20.539 23.871 19.4464 24.324C18.3538 24.7769 17.1828 25.01 16 25.01V25Z"
                fill="white"
              />
              <path
                d="M21.0001 15H18.2901C18.0604 14.4736 17.6564 14.0424 17.1461 13.7788C16.6358 13.5152 16.0503 13.4354 15.488 13.5527C14.9258 13.6701 14.4211 13.9774 14.0588 14.4231C13.6966 14.8688 13.4988 15.4257 13.4988 16C13.4988 16.5744 13.6966 17.1312 14.0588 17.5769C14.4211 18.0226 14.9258 18.3299 15.488 18.4473C16.0503 18.5646 16.6358 18.4848 17.1461 18.2212C17.6564 17.9576 18.0604 17.5264 18.2901 17H21.0001C21.2653 17 21.5197 16.8946 21.7072 16.7071C21.8948 16.5196 22.0001 16.2652 22.0001 16C22.0001 15.7348 21.8948 15.4804 21.7072 15.2929C21.5197 15.1054 21.2653 15 21.0001 15Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_493_973">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p class="ml-2 text-sm font-semibold">Register</p>
        </div>

        <span class="nav-divider"></span>
        <div
          routerLink="/app/enote-creation"
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:text-brand-green green-hover"
        >
          <img class="w-5 h-5" src="assets/images/add-doc.svg" />
          <p class="ml-2 text-sm font-semibold">Create</p>
        </div>

        <span class="nav-divider"></span>
        <div
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:text-brand-green green-hover"
        >
          <img class="w-5 h-5 white-icon" src="assets/icons/macros.svg" />
          <p class="ml-2 text-sm font-semibold">Macros</p>
        </div>
      </div>
    </div>

    <!-- Right buttons wrapper -->
    <div class="flex self-center justify-end w-1/2 pr-2 mb-2 navbar-end">
      <!-- <a
        href="https://help.escrowtab.com/hc/en-us"
        target="_blank"
        class="inline-flex pr-5 text-base font-bold text-midnight link link-hover"
      >
        <p>Support</p>
      </a> -->
      <button
        class="inline-flex pl-5 pr-5 capitalize bg-white rounded-full text-midnight btn btn-sm right-7"
        (click)="logout()"
      >
        <p>Logout</p>
      </button>
    </div>
  </div>
</div>

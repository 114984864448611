import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@et/auth';

@Component({
  selector: 'e-vault-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  date = new Date();
  constructor(private authService: AuthService) {}

  /**
   * Log out the user.
   * @returns None
   * @memberof NavbarComponent
   */
  logout(): void {
    this.authService.logout();
  }
}

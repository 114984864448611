import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { JwtGuard } from '@et/guards';
import { ProfileResolver } from '@et/resolvers';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'app',
        component: HomeComponent,
        canActivate: [JwtGuard, MsalGuard],
        resolve: { profile: ProfileResolver },
        children: [
          {
            path: 'enote-creation',
            loadChildren: () =>
              import('@et/evault/enote-creation').then(
                (mod) => mod.EnoteCreationModule,
              ),
          },
          {
            path: 'enote-tools',
            loadChildren: () =>
              import('@et/evault/enote-tools').then(
                (mod) => mod.EvaultEnoteToolsModule,
              ),
          },
          {
            path: 'evault-home',
            loadChildren: () =>
              import('@et/evault/evault-home').then(
                (mod) => mod.EvaultEvaultHomeModule,
              ),
          },
        ],
      },
      {
        path: 'auth',
        loadChildren: () => import('@et/auth').then((mod) => mod.AuthModule),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('@et/error-pages').then((mod) => mod.ErrorPagesModule),
      },
      { path: '', redirectTo: 'app/evault-home', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'app', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

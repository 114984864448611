import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  InteractionStatus,
  AccountInfo,
  EventType,
  EventCallbackFunction,
} from '@azure/msal-browser';
import { SetAADUserWithClaims } from '@et/guards';
import { AADUserWithClaims } from '@et/typings';
import { initTracking } from '@et/utils';
import { Store } from '@ngxs/store';
import { filter, takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'e-vault-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'evault';
  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msal: MsalService,

    private store: Store,
  ) {}
  ngOnInit() {
    initTracking();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        const account = this.msal.instance.getActiveAccount();

        this.store.dispatch(
          new SetAADUserWithClaims(account as AADUserWithClaims),
        );
      });
    this.setMsalInstaceCallback();
  }

  /**
   * Sets the active account after a successful login redirect
   */
  private setMsalInstaceCallback() {
    const cb = (event: {
      eventType: EventType;
      payload: { account: AccountInfo };
    }) => {
      // set active account after redirect
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        this.msal.instance.setActiveAccount(account);

        this.msal.loginRedirect();
      }
    };
    this.msal.instance.addEventCallback(cb as EventCallbackFunction);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

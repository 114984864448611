<!-- Header -->
<div class="border-b h-14 border-neutral">
  <div class="flex items-center w-full h-full gap-2">
    @if (showListView) {
    <div
      class="flex items-center flex-1 h-full gap-2 px-3 cursor-pointer"
      (click)="changeMode('list-view')"
      [ngClass]="{ 'text-white bg-light': mode === 'list-view' }"
    >
      @if (mode === 'list-view') {
      <img
        src="assets/images/format-list-bulleted-white.svg"
        alt="File"
        width="20"
        height="20"
      />
      <span>List View</span>
      } @else {
      <img
        src="assets/images/format-list-bulleted-sand.svg"
        alt="File"
        width="20"
        height="20"
      />
      <span>List View</span>
      }
    </div>
    }
    <div
      class="flex items-center flex-1 h-full gap-2 px-3 cursor-pointer"
      (click)="changeMode('page-view')"
      [ngClass]="{ 'text-white bg-light': mode === 'page-view' }"
    >
      <img src="assets/images/file.svg" alt="File" width="20" height="20" />
      <span>Page View</span>
    </div>
  </div>
</div>

@if (mode === 'page-view') {
<!-- Pages scroll container -->
<cdk-virtual-scroll-viewport
  itemSize="226"
  [minBufferPx]="minBufferPx"
  [maxBufferPx]="maxBufferPx"
  class="flex flex-col items-center justify-start flex-grow w-full gap-3 px-4"
>
  <div
    *cdkVirtualFor="
      let page of dataSource;
      let cdkVirtualForTrackBy = trackBy;
      let i = index
    "
    class="flex flex-col items-center gap-1 pt-4"
  >
    <ng-container
      *ngTemplateOutlet="
        page ? pageLoaded : pageLoading;
        context: { i, page, selectedPage }
      "
    >
    </ng-container>
  </div>

  <!-- Page -->
  <ng-template #pageLoaded let-page="page" let-selectedPage="selectedPage">
    <div
      [ngClass]="{
        '!border-primary border-2': selectedPage === page.pageNumber,
        'cursor-pointer': selectedPage !== page.pageNumber
      }"
      class="relative flex flex-col items-center gap-1 px-8 pb-2 bg-white rounded-lg pt-11"
    >
      <div class="absolute flex gap-1 top-2 right-2">
        @if (page.hasSignatureRequired) {
        <img
          src="assets/images/signature-required.svg"
          alt="File"
          width="24"
          height="24"
        />
        } @else if (page.isSignatureComplete) {
        <img
          src="assets/images/signature-required-green.svg"
          alt="File"
          width="24"
          height="24"
        />
        } @if (page.hasStampRequired) {
        <img
          src="assets/images/stamp-required.svg"
          alt="File"
          width="24"
          height="24"
        />
        } @else if (page.isStampComplete) {
        <img
          src="assets/images/stamp-required-green.svg"
          alt="File"
          width="24"
          height="24"
        />
        }
      </div>
      <img
        [src]="page.img"
        (click)="onSelectPage(page.pageNumber)"
        class="transition-all border-2 border-transparent border-base-300"
        tabindex="0"
        alt="Page  {{ page.pageNumber }}"
      />
      <span>{{ page.pageNumber }}</span>
    </div>
  </ng-template>

  <!-- Page placeholder -->
  <ng-template #pageLoading let-i="i">
    <div
      class="flex items-center justify-center m-2 w-[150px] h-[200px] border border-secondary-focus"
    >
      <et-atoms-spinner></et-atoms-spinner>
    </div>
    <span>{{ i + 1 }}</span>
  </ng-template>
</cdk-virtual-scroll-viewport>
} @if (mode === 'list-view') {
<div>
  <!-- scrollable list without attaching hardcoded height -->
  <div class="flex flex-col items-start gap-1 pl-5 pr-16 overflow-y-auto">
    @for (item of listItems; track item.title) {
    <div class="flex items-start gap-2 py-2">
      @if (item.hasSignatureRequired) {
      <img
        src="assets/images/signature-required.svg"
        alt="File"
        width="24"
        height="24"
      />
      } @else if (item.isSignatureComplete) {
      <img
        src="assets/images/signature-required-green.svg"
        alt="File"
        width="24"
        height="24"
      />
      } @else {
      <span class="w-5 h-5">&nbsp;</span>
      } @if (item.hasStampRequired) {
      <img
        src="assets/images/stamp-required.svg"
        alt="File"
        width="24"
        height="24"
      />
      } @else if (item.isStampComplete) {
      <img
        src="assets/images/stamp-required-green.svg"
        alt="File"
        width="24"
        height="24"
      />
      } @else {
      <span class="w-5 h-5">&nbsp;</span>
      }
      <a
        (click)="onSelectFromList(item.title)"
        class="text-base font-normal truncate cursor-pointer text-midnight"
        >{{ item.title }}</a
      >
    </div>
    }
  </div>
</div>
}

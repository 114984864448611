import {
  SELECT_PARENT_COMPONENT,
  selectConnectedPosition,
} from './select-input.config';
import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import { ViewportRuler } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  DOWN_ARROW,
  ENTER,
  hasModifierKey,
  LEFT_ARROW,
  RIGHT_ARROW,
  TAB,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SelectOptionComponent } from './../select-option/select-option.component';
import { selectReveal } from './select-input.animations';
import { EtSelectIconDirective } from './select-icon.directive';
import { SelectionModel } from '@angular/cdk/collections';

interface SelectedOption {
  value: string | number | undefined | boolean;
}

@Component({
  selector: 'et-atoms-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  animations: [selectReveal],
  providers: [
    { provide: SELECT_PARENT_COMPONENT, useExisting: SelectInputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectInputComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectInputComponent
  implements OnInit, OnDestroy, AfterContentInit, ControlValueAccessor
{
  /**
   * Responsible for typeahead functionality
   * @default true
   */
  @HostBinding('class.searchable')
  @Input()
  searchable = true;

  @Input()
  searchableNotFoundMsg = 'Option not found';

  /**
   * Prevent internal search filtering
   * @default false
   */
  @Input() preventSearchableFilter = false;

  /** Whether the user should be allowed to select multiple options. */
  @Input({ transform: booleanAttribute })
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: boolean) {
    this._multiple = value;
  }
  private _multiple: boolean = false;

  searchableCtrl = new FormControl<string>('');

  @Output() readonly selectionChange = new EventEmitter();
  @Output() readonly searchChanged = new EventEmitter<string>();

  triggerRect!: ClientRect;

  keyManager!: ActiveDescendantKeyManager<SelectOptionComponent>;

  onChange!: (value: string | string[]) => void;
  onTouched!: () => void;

  private _displayOption: SelectedOption | undefined;

  private _selectedOption!: SelectOptionComponent;

  private _value: string | string[] | undefined;

  @HostBinding('class.opened')
  private _panelOpen = false;

  private _focused = false;

  private _disabled = false;

  /** Deals with the selection logic. */
  private _selectionModel!: SelectionModel<SelectOptionComponent>;

  private readonly destroy$ = new Subject<void>();

  positions: ConnectedPosition[] = selectConnectedPosition;

  @HostBinding('attr.tabIndex') tabIndex = 0;

  @HostBinding('attr.role') role = 'combobox';

  @HostBinding('attr.aria-autocomplete') autocomplete = 'none';

  @ContentChildren(SelectOptionComponent, { descendants: true })
  private selectOptions!: QueryList<SelectOptionComponent>;

  @ContentChild(EtSelectIconDirective)
  icon!: ElementRef;

  @ViewChild('trigger')
  private trigger!: ElementRef;

  @ViewChild('searchableInput')
  private searchableInput!: ElementRef<HTMLInputElement>;

  @ViewChild(CdkConnectedOverlay, { static: true })
  private connectedOverlay!: CdkConnectedOverlay;

  @ViewChild('panel')
  private panel!: ElementRef;

  @ViewChild('notFoundMsgContainer', { read: ViewContainerRef })
  private notFoundMsgContainer!: ViewContainerRef;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.panelOpen
      ? this.handleOpenKeyDown(event)
      : this.handleClosedKeyDown(event);
  }

  @HostListener('focus') onFocus() {
    if (!this.disabled) {
      this._focused = true;
    }
  }

  @HostListener('blur') onBlur() {
    this._focused = false;
    if (!this.disabled && !this.panelOpen) {
      this.changeDetectorRef.markForCheck();
    }
  }

  get panelOpen(): boolean {
    return this._panelOpen;
  }

  get focused(): boolean {
    return this._focused;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get displayValue(): string {
    if (this.multiple) {
      return this.getSelectedValues();
    } else {
      return this._displayOption?.value?.toString() || '';
    }
  }

  get selectedOption(): SelectOptionComponent {
    return this._selectedOption;
  }

  get empty(): boolean {
    return !this._selectedOption;
  }

  get windowWidth() {
    return this.triggerRect?.width as string | number;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private viewportRuler: ViewportRuler,
  ) {}

  ngOnInit(): void {
    this._selectionModel = new SelectionModel<SelectOptionComponent>(
      this.multiple,
    );

    this.viewportRuler
      .change()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.panelOpen) {
          this.triggerRect = this.trigger.nativeElement.getBoundingClientRect();
          this.changeDetectorRef.markForCheck();
        }
      });

    this.searchableCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.handleSearchInput(value));
  }

  ngAfterContentInit() {
    this.initKeyManager();
    this.initializeSelection();
    // Update service when options changes
    this.selectOptions.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // Select an option after passing new options
      this.selectOption();
    });

    this._selectionModel.changed
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        event.added.forEach((option) => option.select());
        event.removed.forEach((option) => option.deselect());
      });
  }

  /**
   * Update select option with value
   * @param {string} value - value to select
   *
   * @memberOf SelectInputComponent
   */
  writeValue(value: string): void {
    if (this.multiple && !Array.isArray(value)) {
      throw new Error('Value must be an array in multiple select field');
    }
    this._value = value;
    this.setOption();
  }

  /**
   * Set option value without triggering value change
   *
   * @memberOf SelectInputComponent
   */
  setOption() {
    // Set optoins for multiple select
    if (this.multiple && this.selectOptions) {
      this.setOptionMultiple();
      return;
    }

    // Set option for single select
    const selectedOption = this.selectOptions?.find(
      (o) => o.value === this._value,
    );
    if (this.selectOptions && selectedOption) {
      this._displayOption = {
        value: selectedOption.displayValue || selectedOption.value,
      };
      this._selectedOption = selectedOption;
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
      this.keyManager.setActiveItem(selectedOption);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
      this._displayOption = undefined;
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Select option from private value property
   *
   * @memberOf SelectInputComponent
   */
  selectOption() {
    const selectedOption = this.selectOptions?.find(
      (o) => o.value === this._value,
    );
    if (this.selectOptions && selectedOption) {
      this.onSelect(selectedOption);
    }
  }

  /**
   * Value Accesser register onChange function
   * @param {function} fn - function to call whten calling onChange method
   * @memberOf SelectInputComponent
   */
  registerOnChange(fn: (_: string | string[]) => void): void {
    this.onChange = fn;
  }

  /**
   * Value Accesser register OnTouched function
   * @param {function} fn - function to call whten calling OnTouched method
   * @memberOf SelectInputComponent
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Value Accesser handling disabled state
   * @param {boolean} isDisabled - boolean
   * @memberOf SelectInputComponent
   */
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  /**
   * Handle scroll position and selected item when opening select menu
   *
   * @memberOf SelectInputComponent
   */
  onAttach() {
    this.connectedOverlay.positionChange.pipe(take(1)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
      this.highlightOption();

      if (this.panelOpen && this.panel) {
        this.scrollPosition(this.keyManager.activeItemIndex || 0);
      }
    });
    this.updateRect();
  }

  /**
   * Selects an option
   * @param {SelectOptionComponent} option - SelectOptionComponent
   * @memberOf SelectInputComponent
   */
  onSelect(option: SelectOptionComponent) {
    if (this.multiple) {
      this.onSelectMultiple(option);
      return;
    }

    this._selectedOption = option;
    this._displayOption = { value: option.displayValue || option.value };
    this.keyManager.setActiveItem(option);
    this.close();
    this.focus();
    if (option.value || option.value === 0) {
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
    }

    this.onChange && this.onChange(option.value as string);
    this.selectionChange.emit(option.value);

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Opens select menu
   *
   * @memberOf SelectInputComponent
   */
  open() {
    if (this._disabled) {
      return;
    }
    if (this.onTouched) {
      this.onTouched();
    }
    this.updateRect();
    this.highlightOption();
    this.keyManager.withHorizontalOrientation(null);
    this._panelOpen = !this._panelOpen;
    if (this.searchable) {
      setTimeout(() => {
        this.searchableInput?.nativeElement.focus();
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  /**
   * Close select menu
   */
  close() {
    if (this.panelOpen) {
      this._panelOpen = false;
      this.searchableCtrl.reset();
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Handles the selection and deselection of multiple options in a multi-select
   * menu input after user interaction.
   *
   * This function toggles the selection state of the provided option. If the option is already selected,
   * it is deselected; otherwise, it is selected. It updates the CSS class of the component based on whether
   * any options are selected. It then retrieves the values of all selected options and emits them through
   * the `onChange` callback and the `selectionChange` event. If the input is searchable, it focuses on the
   * searchable input element. Finally, it marks the component for change detection.
   *
   * @param {SelectOptionComponent} option - The option to be selected or deselected.
   * @private
   */
  private onSelectMultiple(option: SelectOptionComponent) {
    this.onSelectMultiple(option);
    option.selected
      ? this._selectionModel.deselect(option)
      : this._selectionModel.select(option);
    if (this._selectionModel.isEmpty()) {
      this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
    } else {
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
    }

    const selectedValues = this._selectionModel.selected.map(
      (o) => o.value as string,
    );
    this.onChange && this.onChange(selectedValues);
    this.selectionChange.emit(selectedValues);
    if (this.searchable) {
      this.searchableInput?.nativeElement.focus();
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Sets the selected options for a multi-select input without user interaction.
   *
   * This function clears the current selection model and iterates over the provided values.
   * For each value, it finds the corresponding option in the select options and selects it.
   * It updates the CSS class of the component based on whether any options are selected.
   * Finally, it marks the component for change detection.
   *
   * @private
   */
  private setOptionMultiple() {
    this._selectionModel.clear();
    (this._value as string[]).forEach((val) => {
      const option = this.selectOptions.find(
        (o) => o.displayValue === val || o.value === val,
      );
      if (option) {
        this._selectionModel.select(option);
      }
    });
    if (this._selectionModel.isEmpty()) {
      this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
    } else {
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Initializes the selection for the select input component.
   *
   * This function sets the initial value of the select input component. It defers the setting of the value
   * to avoid "Expression has changed after it was checked" errors from Angular. The function handles both
   * single and multiple selection modes:
   * - For multiple selection, it ensures the value is an array and selects the corresponding options.
   * - For single selection, it selects the corresponding option and updates the display value.
   * In both cases, it adds the 'selected' class to the element if a selection is made.
   *
   * @private
   */
  private initializeSelection() {
    // Defer setting the value in order to wait for the options to be initialized
    Promise.resolve().then(() => {
      const value = this._value;

      // Select multiple values
      if (value && this.multiple) {
        const isArray = Array.isArray(value);
        if (!isArray)
          throw new Error('Value must be an array in multiple select field');
        value.forEach((val) => {
          const option = this.selectOptions.find(
            (o) => o.displayValue === val || o.value === val,
          );
          if (option) {
            this._selectionModel.select(option);
          }
        });
        if (!this._selectionModel.isEmpty()) {
          this.renderer.addClass(this.elementRef.nativeElement, 'selected');
        }
      } else if (value) {
        // Select single value
        const option = this.selectOptions.find(
          (o) => o.displayValue === value || o.value === value,
        );
        if (option) {
          this._selectedOption = option;
          this._displayOption = { value: option.displayValue || option.value };
          this.keyManager.setActiveItem(option);
          this.renderer.addClass(this.elementRef.nativeElement, 'selected');
        }
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * This function is called when the user types something in the search input
   * 1. It will emit the searchChanged event
   * 2. It will filter the options based on the input if preventSearchableFilter
   * is false
   * @param {string} search search string
   */
  private handleSearchInput(search: string | null) {
    this.searchChanged.emit(search?.toString());

    if (this.preventSearchableFilter) return;

    this.notFoundMsgContainer?.clear();

    this.selectOptions.forEach((option) => {
      const displayValue = option.displayValue?.toString().toLowerCase();
      const fieldValue = option.value?.toString().toLowerCase();
      const searchValue = displayValue || fieldValue;
      if (
        search &&
        searchValue &&
        searchValue?.indexOf(search?.toLowerCase()) > -1
      ) {
        option.show();
      } else if (!search) {
        option.show();
      } else {
        option.hide();
      }
    });
    const hasOptions = this.selectOptions.some((option) => !option.hidden);
    if (hasOptions) {
      this.keyManager.setFirstItemActive();
      this.scrollPosition(this.keyManager.activeItemIndex || 0);
    } else {
      this.showNoOptionsMsg();
    }
    this.changeDetectorRef.markForCheck();
  }

  private showNoOptionsMsg() {
    this.notFoundMsgContainer.clear();
    const componentRef = this.notFoundMsgContainer.createComponent(
      SelectOptionComponent,
    );
    componentRef.setInput('value', this.searchableNotFoundMsg);
    componentRef.setInput('disabled', true);
    componentRef.setInput('hideCheckbox', true);
  }

  /**
   * Init key manager to select with keys
   *
   * @memberOf SelectInputComponent
   */
  private initKeyManager() {
    this.keyManager = new ActiveDescendantKeyManager<SelectOptionComponent>(
      this.selectOptions,
    )
      .withTypeAhead()
      .withVerticalOrientation()
      .withHorizontalOrientation('ltr')
      .withHomeAndEnd()
      .withAllowedModifierKeys(['shiftKey'])
      .withWrap()
      .skipPredicate((option) => option.hidden);

    this.keyManager.tabOut.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.focus();
      this.close();
    });
  }

  /**
   * Navigate select menu with keys
   *
   * @memberOf SelectInputComponent
   */
  private handleClosedKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    const isArrowKey =
      keyCode === DOWN_ARROW ||
      keyCode === UP_ARROW ||
      keyCode === LEFT_ARROW ||
      keyCode === RIGHT_ARROW;
    const isOpenKey = keyCode === ENTER;
    const manager = this.keyManager;

    if (
      (!manager.isTyping() && isOpenKey && !hasModifierKey(event)) ||
      (event.altKey && isArrowKey)
    ) {
      event.preventDefault();
      this._panelOpen = true;
      setTimeout(() => {
        this.searchableInput?.nativeElement.focus();
        this.changeDetectorRef.markForCheck();
      });
    } else {
      manager.onKeydown(event);
    }
  }

  /**
   * Open / close menu with keys
   *
   * @memberOf SelectInputComponent
   */
  private handleOpenKeyDown(event: KeyboardEvent) {
    const manager = this.keyManager;
    const keyCode = event.keyCode;
    const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW;
    const isTyping = manager.isTyping();
    const isTab = keyCode === TAB;

    if (isArrowKey && event.altKey) {
      // Close the select on ALT + arrow key to match the native <select>
      event.preventDefault();
      this.close();
    } else if (
      !isTyping &&
      keyCode === ENTER &&
      manager.activeItem &&
      !hasModifierKey(event)
    ) {
      event.preventDefault();
      this.onSelect(manager.activeItem);
    } else if (isArrowKey && this.panelOpen && this.panel) {
      manager.onKeydown(event);
      this.scrollPosition(this.keyManager.activeItemIndex || 0);
    } else if (isTab) {
      this.focus();
      this.close();
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Fires focus event on select component
   * @param {FocusOptions} options - focus options
   * @memberOf SelectInputComponent
   */
  private focus(options?: FocusOptions) {
    this.elementRef.nativeElement.focus(options);
  }

  /**
   * Highlights menu depending on selected option
   *
   * @memberOf SelectInputComponent
   */
  private highlightOption(): void {
    if (this.keyManager) {
      if (this.empty) {
        this.keyManager.setFirstItemActive();
      } else {
        this.keyManager.setActiveItem(this._selectedOption);
      }
    }
  }

  /**
   * Scrolls to selected option on long option list
   * @param {Number} index - focus options
   * @memberOf SelectInputComponent
   */
  private scrollPosition(index: number) {
    if (index === 0 && this.panel) {
      this.panel.nativeElement.scrollTop = 0;
    } else {
      this.selectOptions.get(index)?.scrollIntoView({ block: 'center' });
    }
  }

  /**
   * Updates select field coordinates
   *
   * @memberOf SelectInputComponent
   */
  private updateRect() {
    this.triggerRect = this.trigger.nativeElement.getBoundingClientRect();
  }

  /**
   * Retrieves the selected values from the selection model as a string.
   *
   * This function maps over the selected options in the selection model and extracts their display values
   * or values. It then joins these values into a single string, separated by commas.
   *
   * @returns {string} - A comma-separated string of the selected values.
   * @private
   */
  private getSelectedValues(): string {
    return this._selectionModel.selected
      .map((o) => o.displayValue || o.value)
      .join(', ');
  }
}

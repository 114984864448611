import { ConnectedPosition } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { SelectOptionComponent } from '../select-option/select-option.component';

export const SELECT_PANEL_HEIGHT = 256;

export const SELECT_PANEL_PADDING = 5;

export const SELECT_OPTION_HEIGHT = 39;

export const selectConnectedPosition: ConnectedPosition[] = [
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 1,
  },
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -1,
  },
];

/**
 * Describes a parent component that manages a list of options.
 */
export interface SelectParentComponent {
  multiple?: boolean;
  selectedOption: SelectOptionComponent;
  onSelect: (option: SelectOptionComponent) => void;
}

/**
 * Injection token used to provide the parent component to options.
 */
export const SELECT_PARENT_COMPONENT =
  new InjectionToken<SelectParentComponent>('SELECT_PARENT_COMPONENT');
